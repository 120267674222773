import React from 'react';

import styles from './slider.module.scss';

const Slider = () => (
  <div>
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/KOLEJ_WIDOKOWA.jpg`}
      alt="RH Sport"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/MONSTERROLLER1.jpg`}
      alt="RH Sport"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/MONSTERROLLER2.jpg`}
      alt="RH Sport"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/SFFESTIVAL.jpg`}
      alt="RH Sport"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/SFFESTIVAL2.jpg`}
      alt="RH Sport"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/SFFESTIVAL3.jpg`}
      alt="RH Sport"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/VELO.jpg`}
      alt="RH Sport"
      className={styles.wrapper}
    />
  </div>
);

export default Slider;
