import React, { useState, useEffect } from 'react';
import axios from 'axios';

import GlobalStyle from '../theme/GlobalStyle';
import styles from './App.module.scss';

import SliderA from '../components/sliderA';
import SliderB from '../components/sliderB';
import SliderC from '../components/sliderC';

const App = () => {
  const [startWeatherData, setStartWeatherData] = useState([]);
  const [startWeatherDataDaily, setStartWeatherDataDaily] = useState([]);
  const [startWeatherForecast, setStartWeatherForecast] = useState([]);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const Play = () => {
    setFirst(true);

    setTimeout(() => {
      setSecond(true);
    }, 55 * 1000);

    setTimeout(() => {
      setFirst(false);
    }, 56 * 1000);

    setTimeout(() => {
      setThird(true);
      setSecond(false);
    }, 85 * 1000);

    setTimeout(() => {
      setThird(false);
    }, 105 * 1000);
  };

  useEffect(() => {
    async function fetchData() {
      const lat = 49.3906;
      const lon = 20.0973;
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },

        url: `https://api.bergregions.pl/api/v1/weather?lat=${lat}&lon=${lon}`,
      }).then((response) => {
        setStartWeatherData(response.data.currentCondition);
        setStartWeatherDataDaily(response.data.daily[0]);
        setStartWeatherForecast(response.data.today);
      });
    }
    fetchData();

    Play();
    setInterval(() => {
      Play();
    }, 105 * 1000);

    const check = () => {
      const minutes = new Date().getMinutes();
      if (minutes === 0 || minutes === 30) {
        fetchData();
      }
    };

    setInterval(() => {
      check();
    }, 1000 * 60);
  }, []);

  return (
    <>
      <GlobalStyle />
      <div className={styles.wrapper}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/SFFESTIVAL3.jpg`}
          alt="RH Sport"
          className={styles.background}
        />
        {first && <SliderA />}
        {second && <SliderB />}
        {third && (
          <SliderC
            startWeatherData={startWeatherData}
            startWeatherDataDaily={startWeatherDataDaily}
            startWeatherForecast={startWeatherForecast}
          />
        )}
      </div>
    </>
  );
};

export default App;
