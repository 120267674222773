import React from 'react';

import ProgressBar from './ProgressBar/progress';
import Slider from './Slider/slider';

const SliderA = () => (
  <>
    <ProgressBar />
    <Slider />
  </>
);

export default SliderA;
