import React from 'react';

import Progress from './ProgressBar/progress';
import Movie from './Movie/movie';

const SliderB = () => (
  <>
    <Progress />
    <Movie />
  </>
);

export default SliderB;
